import React from "react";
import {Helmet} from 'react-helmet';
import {Link} from "react-router-dom";

export const Splash = () => (
    <div className={'grid-container'}>
        <div className={'grid-x grid-padding-x grid-padding-y splash'}>
            <Helmet>
                <title>Billie Shepard | Techniques for Persuasive Speaking and Overcoming Fear</title>
                <meta name="description"
                      content="Public Speaking training for more effective confident interviewing with one-on-one coaching and taped playback."/>
                <meta name="keywords"
                      content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
                <body className={'site-splash'}/>
            </Helmet>

            <div className={'cell'}>
                <div className="grid-x grid-padding-x grid-padding-y">
                    <div className="cell small-8 medium-4">
                        <Link to="/intro/">
                            <img src="/images/site/global/logo.jpg" alt="Billie Shepard" className={'logo'}/>
                        </Link>
                    </div>
                    <div className="cell">
                        <div className="grid-x grid-padding-x grid-padding-y align-middle splash-content">

                            <div className={'cell medium-3 large-2 show-for-medium text-center'}>
                                <a href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships-ebook/dp/B084WZZMNM/'}
                                   target={'_blank'} rel={'noopener noreferrer'}
                                   className={'amazon-btn'}>
                                    <img src="/images/site/splash/billie-shepard-amazon-button.png" alt=""/>
                                </a>
                            </div>
                            <div className={'cell small-5 medium-3 text-center'}>
                                <a href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships-ebook/dp/B084WZZMNM/'}
                                   target={'_blank'} rel={'noopener noreferrer'}
                                   className={'book-btn'}>
                                    <img src="/images/site/splash/billie-shepard-amazon-presentation-method-mobile.png"
                                         alt=""/>
                                </a>
                                <a href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships-ebook/dp/B084WZZMNM/'}
                                   target={'_blank'} rel={'noopener noreferrer'}
                                   className={'hide-for-medium'}>
                                    <img src="/images/site/splash/billie-shepard-amazon-button.png" alt=""/>
                                </a>
                            </div>
                            <div className={'cell small-7 medium-6'}>
                                <h3>High-powered Training <br/> for Corporate Speakers</h3>
                                <p><Link className={'button'} to="/intro/">Learn More</Link></p>
                            </div>
                        </div>
                    </div>


                </div>


            </div>

        </div>
    </div>

)

