import React from "react";
import {Helmet} from "react-helmet";

// document.body.classList.add('site-approach');

export const Approach = () => (
    <div className={'grid-x grid-padding-x grid-padding-y'}>
        <Helmet>
            <title>Approach</title>
            <meta name="description" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <meta name="keywords" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <body className={'site-approach'}/>
        </Helmet>
        <div className={'cell'}>
            <h1>Approach</h1>
            <p><em>"Public speaking is the number one fear in America; even greater than the fear of dying. So that
                means if you're at a funeral, it's better to be the deceased than the person giving the eulogy."</em>
            </p>
            <p><em>&mdash; Jerry Seinfeld</em></p>

            <p>As the presenter, it's your responsibility to create the electrical current that engages, inspires and
                wins your audience to your side.</p>

            <p>Billie Shepard & Associates will walk you through the communication process in a way that will help you
                relax, hone your individual style and discover your unique strengths as a presenter. You'll learn how to
                direct the electricity of your message to connect with your audience, creating a living bond that goes
                to the core of each participant - to motivate, excite and enlighten.</p>

            <p>Bridging the corporate and artistic worlds, Billie Shepard leverages the same theatrical techniques used
                by Sophocles in 440 B.C. to successfully mesmerize, charm, affect, educate and motivate audiences. In
                developing this approach, Billie Shepard has tapped the most effective theatre techniques, customized
                them for application to the business world and developed some new ones as well. She and her team can help any
                presenter improve their leadership presence, develop their strategic message, and deliver it in the most
                powerful way possible.</p>

            <h4>WHAT YOU WILL LEARN</h4>

            <ul>
                <li>Easy methods of relaxation</li>
                <li>How to cope with stage fright</li>
                <li>How to turn nervous energy into a tool that works for you</li>
                <li>How to use the communication process to your advantage</li>
                <li>How to grab audience attention and hold it</li>
                <li>How to organize your presentation</li>
                <li>How to speak without jargon</li>
                <li>How and when to use notes</li>
                <li>Techniques to convey confidence and leadership</li>
                <li>How to make eye contact in small and large groups</li>
                <li>How and when to use humor</li>
                <li>How to craft a quotable sound bite</li>
                <li>How to emphasize a point without resorting to repetition</li>
                <li>How to effectively use gestures and vocal projection</li>
                <li>How to use microphones and pointers to your advantage</li>
                <li>Knowing when to end</li>
                <li>How to close in a compelling manner</li>
                <li>How to handle objections</li>
                <li>How to effectively manage Q&amp;A sessions</li>
                <li>How others have used their unique gifts to deliver memorable presentations</li>
            </ul>


        </div>
    </div>
)

