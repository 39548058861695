import React from "react";
import {Helmet} from "react-helmet";

export const Kudos = () => (
    <div className={'grid-x grid-padding-x grid-padding-y'}>
        <Helmet>
            <title>Kudos</title>
            <meta name="description" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <meta name="keywords" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <body className={'site-kudos'}/>
        </Helmet>
        <div className={'cell'}>
            <h1>Kudos</h1>
            <h3>WHAT CLIENTS ARE SAYING:</h3>

            <blockquote>
                “Her approach and techniques embody the key principles needed to unleash an individual’s talent:
                awareness, intent, and personal style. Regardless of their experience level, the Rackers she’s worked
                with are truly challenged in class and are able to reap the rewards of improved thinking and presenting
                to groups of all kinds. Billie has made a real difference.”
                <cite><strong>Wesley Jess</strong> <br/>
                    Vice President, Supply Chain Operations, Rackspace</cite>
            </blockquote>

            <blockquote>
                “I have worked with Billie for many years and she never fails to elevate the presentation skills of both
                novices and senior executives to an entirely new realm of genuine impact and authentic connection
                between speaker and audience. She is the coach you always want in your corner.”
                <cite><strong>Lynne Born </strong><br/>
                    President, Wealth Architects</cite>
            </blockquote>

            <blockquote>
                “Billie Shepard took my public speaking abilities to the next level and helped me and my voice. Before
                working with Billie, I had a deep fear of public speaking, but through her process I was able to
                overcome those fears and become the speaker I was meant to be.”
                <cite><strong>Bernard C. Coleman III </strong><br/>
                    Head of Inclusive Engagement, Uber</cite>
            </blockquote>
            <blockquote>
                “I have taken many presentation skills seminars in the course of my career, but none have measured up to
                the fast success I had in working with Billie. Her hands-on approach and fast, effective coaching
                quickly get you to the next level. I recently had my entire team attend her seminar and I am amazed at
                the results, each improved their game 1-2 levels easily. This has been a great help and I will continue
                to leverage Billie as a training asset.”
                <cite><strong>Patrick Maiorano </strong><br/>
                    Business Planning & Development, Supply Chain, Intuit</cite>
            </blockquote>
            <blockquote>

                “Billie guides her clients and arms them with delivery skills that are infused with passion and
                persuasion. Billie is an innovator in the field of corporate presentation and a critical asset to any
                business person trying to sharpen communication skills.”
                <cite><strong>Heidi Mason </strong><br/>
                    Managing Partner, The Bell-Mason Group<br/>
                    Author, “Venture Imperative”</cite>
            </blockquote>
            <blockquote>
                “I hit a home run with my presentation and would not have done so without your coaching. Having just
                completed our GroundZero Conference in L.A., I'd like to thank you for working with our team and helping
                to get our presenters up to the task. Although the team members had reservations about working with a
                coach, in the end everyone was glad we did. You took the raw material that we had and made us
                presentable. Many people, including me, changed our content to improve the presentation, so it wasn't
                just about looking and speaking better. You showed us how to get it right!”
                <cite><strong>John Katsaros </strong><br/>
                    President, Netsedge Research</cite>
            </blockquote>
            <blockquote>
                “Billie’s techniques have helped me become a better presenter, communicator and leader. Whether it’s a
                presentation to the board, at a team meeting or in a group situation, I enter prepared, secure and
                confident. She is an insightful, knowledgeable and caring coach. Her acting and corporate techniques are
                simple, yet extremely powerful.”
                <cite><strong>Katherine Kilbourne </strong><br/>
                    CFO, WellsFargo Securities, LLC</cite>
            </blockquote>
            <blockquote>
                “Billie worked with two groups of women at our company. All were bright, highly trained and educated.
                She taught us how to organize, tighten and energize our presentations and become more powerful,
                effective, and clear presenters. Her efforts greatly improved our effectiveness in the workplace and out
                in the world. Her training affected me deeply.”
                <cite><strong>Lori Cox </strong><br/>
                    Vice President of Human Resources, Novellus</cite>
            </blockquote>
            <blockquote>
                “Your help has been invaluable. Your instruction is showing in my day-to-day work. I keep telling my
                self to 'Fill the room with energy and connections—energy and connections.”
                <cite><strong>Christopher Norris </strong><br/>
                    Vice President, Cypress Semiconductor</cite>
            </blockquote>
            <blockquote>
                “I have personally experienced the value of combining Billie’s acting techniques with the demands of
                making corporate presentations - with tremendously positive results. After working with Billie, I am a
                much improved speaker and leader. I have also seen the results of her direct, intense focus while
                teaching others, to their great improvement. Everyone who works with Billie is better for it. She is a
                life-changing coach. She forces you to be clear and succinct.”
                <cite><strong>Jeanne Wu </strong><br/>
                    Sr. Vice President Human Resources, BEA Systems, Inc.</cite>
            </blockquote>
            <blockquote>
                “We have worked with Billie with a number of our clients' CEOs. The results have been superb! The
                presentation skills Billie has helped our clients learn have helped them be top speakers in the forums
                to which they have presented.”
                <cite><strong>Abigail Johnson </strong><br/>
                    President, Roeder-Johnson Public Relations</cite>
            </blockquote>
            <blockquote>
                “My public speaking skills were in desperate need of a makeover, and Billie assured me that the trembling, red-faced disaster before her would transform into someone who spoke with confidence and captivated a room. Indeed, over the next few weeks, Billie Shepard did just that…In the end, not only Billie armed me with a variety of tools to manage my speaking anxiety, and but also (helped me) create compelling and dynamic presentations. I learned to monitor my breathing, to develop present moment awareness, and to push through the fear until it subsided…I couldn’t have asked for a better teacher and coach.”
                <cite><strong>Linda Nellis </strong><br/>
                    Presentation Skills Class, UC Santa Cruz Extension Spring 2012</cite>
            </blockquote>
            <blockquote>
                "In evaluations following classes, Billie received approximately 4.4 out of 5 on all classes. Student comments ranged from ‘great class’ to ‘very good, well-paced course with friendly instructorrsquo. One student noted that what s/he liked about the class was, ‘Forcing us to go through a growth experience.”
                <cite><strong>Sally Lemee </strong><br/>
                    Program Manager, UCSC Extension Corporate Training</cite>
            </blockquote>
            <blockquote>
                “Billie has a wonderful energy and enthusiasm that fills the room. Her background in theatrics breathes life into work on investor presentations, press meetings and speaking opportunities. She calls it like it is. This type of candor and honesty is the perfect approach for excellence in the field of media communications.”
                <cite><strong>Scott T. Rozic </strong><br/>
                    President and CEO, XMarkstheSpot, Inc.</cite>
            </blockquote>
            <blockquote>
                “My presentation was a hit! Thanks to you I felt confident and it showed. You really have a gift. The hours I spent with you were invaluable.”
                <cite><strong>Janet Herman </strong><br/>
                    Morrison Foerster LLP</cite>
            </blockquote>
            <blockquote>
                “Billie's teaching results speak for themselves!”
                <cite><strong>JP Mark </strong><br/>
                    President, Farm House Equity Research</cite>
            </blockquote>
            <blockquote>
                “She helps you to exorcise the ‘presentation demons.’ She breaks through the analog way of thinking and helps you become a high-definition performer.”
                <cite><strong>Eric Rothman </strong><br/>
                    Financial Analyst, Wells Fargo Van Kasper</cite>
            </blockquote>
            <blockquote>
                “From all of us. Thank you for making this program a success.”
                <cite><strong>The Quest Scholars Program </strong><br/>
                    Stanford University</cite>
            </blockquote>
        </div>
    </div>
)

