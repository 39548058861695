import React from "react";
import {Helmet} from "react-helmet";

export const About = () => (
    <div className={'grid-x grid-padding-x grid-padding-y'}>

        <Helmet>
            <title>About Us</title>
            <meta name="description"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <meta name="keywords"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <body className={'site-about'}/>
        </Helmet>
        <div className={'cell'}>
            <h1>About Us</h1>
            <p>Billie Shepard and Associates is a multidisciplinary team of professional communicators, actors, writers,
                and
                trainers, dedicated to helping you, as a business communicator, achieve your most effective and
                authentic
                performance as a speaker.</p>


            <h4>ABOUT BILLIE</h4>
            <p>Billie Shepard, founder of Billie Shepard and Associates and has been a presentation coach, as well as a
                professional actor and acting coach for over 30 years. She facilitates workshops that train corporate
                executives, engineers, HR professionals, financial analysts, managers, and public speakers from all over
                the
                world to be better presenters, leaders, collaborators, and communicators. Among her clients are Uber,
                Facebook,
                Cisco Systems, Intuit, Splunk, Rackspace, Thumbtack, Lockheed Martin, Seiler LLP, Wells Fargo, Northrop
                Grumman,
                University of California Santa Cruz Silicon Valley Extension, University of California Berkeley
                Extension,
                and
                thousands of professional actors.</p>

            <p>She has been a guest speaker and coach at conferences, seminars, and universities including Harvard
                Alumni
                Association, Stanford University, San Jose State University and San Jose – Silicon Valley Chamber of
                Commerce.
                She was founder and coordinator of the Women in Business Institute at UCSC Extension.</p>

            <p>Billie founded two acting academies and five acting workshops in four states. Among these was the Actors
                Workshop at San Jose Repertory Theatre, Directing Yourself at Voice One Studios in San Francisco, and
                the
                Monologue Workshop at San Francisco’s Full Circle Productions. She has produced and hosted workshops
                including
                such notable guest instructors as Academy Award winner Alan Arkin and Michael Kostroff, costar on The
                Wire.
                Both
                have written about her influence in their books.</p>

            <p>Billie has appeared in lead roles in regional theatre production in California, Texas, and Arizona. She
                has
                been
                featured in TV productions such as Nash Bridges as well as many commercials, including Curel Lotion and
                Camay.</p>

            <p>A Dean’s Scholar with a Bachelor of Arts in Creative Arts and a Master of Arts in Theatre Arts from San
                Jose
                State University. Billie published her Master’s thesis on the application of acting techniques in
                business
                presentations entitled <a href={'https://scholarworks.sjsu.edu/etd_theses/4313/'} target={'_blank'}
                                          rel={'noopener noreferrer'}>The Actor
                    Within: An Exploration of Present-Moment Awareness in Business
                    Presentations</a>.</p>

            <p>Billie wrote <a
                href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships/dp/1734532904/ref=sr_1_1?dchild=1&keywords=billie+shepard&qid=1592853209&sr=8-1'}
                target={'_blank'} rel={'noopener noreferrer'}>The Billie Shepard Presentation Method</a>, to help
                business
                presenters polish their presence and
                become more influential leaders. It is the culmination of her acting and teaching careers and her
                extensive
                educational research.</p>
        </div>
    </div>
)