import React from 'react'

export const Footer = (props) => (
    <footer className={'footer'}>
        <div className={'grid-container'}>
            <div className={'grid-x grid-padding-x grid-padding-y'}>

                <div className={'cell text-center'}>
                    <p>
                        <a href={'https://www.linkedin.com/in/billie-shepard-59b3534/'}
                           className={'social-icon'} target={'_blank'} rel={'noopener noreferrer'}>
                            <img src="/images/site/global/billie-shepard-linkedin.jpg" alt="LinkedIn"/>
                        </a>
                        <a href={'https://www.imdb.me/billieshepard'} className={'social-icon'} target={'_blank'}
                           rel={'noopener noreferrer'}>
                            <img src="/images/site/global/billie-shepard-imdb.jpg" alt="IMBD"/>
                        </a>
                    </p>
                    © 2024 Billie Shepard & Associates, Public Speaking and Actor Training <br/>
                    250 W. El Camino Real, #5204, Sunnyvale, CA 94087<br/>
                    Serving the San Jose, Silicon Valley and San Francisco Bay areas.
                </div>
            </div>
        </div>
    </footer>
)