import React from "react";
import {Helmet} from "react-helmet";

document.body.classList.add('site-coaching');

export const Coaching = () => (
    <div className={'grid-x grid-padding-x grid-padding-y'}>
        <Helmet>
            <title>Coaching</title>
            <meta name="description" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <meta name="keywords" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <body className={'site-coaching'}/>
        </Helmet>
        <div className={'cell'}>
            <h1>Speakers Coaching and Classes</h1>
            <p>Billie Shepard & Associates combines the best of acting techniques and speech training with savvy
                business experience. We offer a variety of training opportunities; remotely and in person, at your
                workplace, in workshop settings and one-on-one coaching sessions.</p>

            <h4>VIRTUAL, LIVE AND ONLINE TRAINING</h4>
            <p>Working remotely? We offer personalized video training to improve your virtual meeting skills, and
                instantly up your effectiveness online. It doesn’t take much! These simple yet powerful techniques help
                you</p>

            <ul>
                <li>Overcome video conference anxiety</li>
                <li>Polish your presence</li>
                <li>Organize and time your presentation or meeting effectively</li>
                <li>Be present with all participants and keep them engaged</li>
                <li>Motivate participants</li>
                <li>Collaborate successfully and enjoy the process</li>
                <li>Connect more deeply with clients</li>
            </ul>

            <h4>IN-HOUSE GROUP WORKSHOPS</h4>
            <p>We will bring our highly interactive workshops on site to your business. These unique workshops are
                designed
                for those who need to enhance their leadership presence and more effectively communicate ideas through
                their
                presentations, conversations and collaborations. Participants immediately improve by applying present
                moment
                awareness acting techniques through improvisation exercises, evidence-based theatrical techniques, and
                direct one-on-one work with the instructor.</p>

            <h4>ONE-ON-ONE COACHING</h4>
            <p>Whether you need to prepare for an upcoming conference, Zoom meeting, challenging conversation, book
                tour,
                keynote speech, panel discussion or wish to polish your overall presentation skills, we offer
                customized,
                individual coaching, remotely, at your office or in a private setting. These sessions are scheduled
                directly
                with Billie by interview and appointment.</p>

            <br/>
            <h2>CLASSES</h2>
            <br/>

            <h4>MASTER PRESENTERS WORKSHOP</h4>
            <p>This three-hour session is offered as a brush-up and polishing workshop. It is offered only to previous
                corporate group or private clients who have already experienced the basic presentation skills techniques
                with Billie. These sessions are scheduled directly with Billie by appointment.</p>

            <h4>IMPROVISATION FOR BUSINESS</h4>
            <p>This Improvisation for Business workshop provides a relaxed, supportive atmosphere, inviting participants
                to
                experiment, share, play and grow while practicing the spontaneous art of improvisational theatre. There
                is
                no demand to be clever or funny. The business person who is focused on bottom-line results will gain
                outcomes in which participants learn how to:</p>
            <ul>
                <li>Overcome blocks to creativity</li>
                <li>Be fully present in each moment.</li>
                <li>Break stagnant convention and create flexible frameworks that support innovation</li>
                <li>Build trust</li>
                <li>Inspire innovation and creativity that will produce lasting change</li>
                <li>Improve management functioning with innovative, compelling skills</li>
                <li>Promote creative problem solving</li>
                <li>Increase self-confidence</li>
                <li>Adapt on the fly. &nbsp;</li>
                <li>Enhance team building, rediscovering the joy of collaborating with others</li>
                <li>Complement sales, marketing, or customer service with breakthrough ideas</li>
                <li>Get passed your nerves and learn to <em>say it anyway</em></li>
                <li>Advance communication skills</li>
                <li>Practice leadership and organizational skills</li>
            </ul>

        </div>
    </div>
)

