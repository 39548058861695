import React from 'react'

export const Header = (props) => (
    <header className={'header'}>
        <div className={'grid-container'}>
            <div className={'grid-x grid-padding-x grid-padding-y'}>
                <div className={'cell small-6 medium-5 medium-offset-0 large-4 logo'}>
                    <a href={'/'}><img src="/images/site/global/logo.jpg" alt="Billie Shepard & Associates"/></a>
                </div>
                <div className="cell small-6 hide-for-medium">
                    <div className="title-bar" data-responsive-toggle="top-nav" data-hide-for="medium">
                        <button className="menu-icon" type="button" data-toggle="" />
                    </div>
                </div>
                <div className={'cell banner show-for-medium text-center medium-text-left'}>
                    <h1>Enhance Your Virtual Presentation Skills with Billie Shepard&trade;</h1>
                </div>
            </div>
        </div>

    </header>
)