import React from 'react'
import { NavLink } from 'react-router-dom'

export const Navigation = (props) => (

    <div className={'grid-container'}>
        <div className={'grid-x grid-padding-x'}>
            <div className={'cell large-9 large-offset-3'}>
                <nav>

                    <div className="top-bar" id="top-nav">
                        <ul className="vertical medium-horizontal menu" data-responsive-menu="accordion medium-dropdown">
                            <li><NavLink exact activeClassName="active" to="/intro/">Intro</NavLink></li>
                            <li><NavLink exact activeClassName="active" to="/approach/">Approach</NavLink></li>
                            <li><NavLink exact activeClassName="active" to="/coaching/">Coaching</NavLink></li>
                            <li><NavLink exact activeClassName="active" to="/kudos/">Kudos</NavLink></li>
                            <li><NavLink exact activeClassName="active" to="/about-us/">About Us</NavLink></li>
                            <li><NavLink exact activeClassName="active" to="/resources/">Resources</NavLink></li>
                            <li><NavLink exact activeClassName="active" to="/tricks-of-the-trade/">Tricks of the Trade</NavLink></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>

    </div>
)