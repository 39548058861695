
import React, {useState, useEffect} from 'react';
import {Route, Switch, useHistory, useLocation} from 'react-router-dom'
import $ from 'jquery'
import Foundation from 'foundation-sites'
import ReactGA from 'react-ga';

// Layouts
import {Main} from "./layouts/Main";

// Components
import {Header} from "./components/Header";
import {Navigation} from "./components/Navigation";
import {Footer} from "./components/Footer";

// Views
import {Splash} from "./views/Splash";
import {Intro} from "./views/Intro";
import {Approach} from "./views/Approach";
import {Coaching} from "./views/Coaching";
import {Kudos} from "./views/Kudos";
import {About} from "./views/About";
import {Resources} from "./views/Resources"
import {Tricks} from "./views/Tricks"
import {NoMatch} from "./views/NoMatch";


$(document).on('click', '.play-audio-btn', function (e) {
    e.preventDefault();
    let audio_player = document.getElementById('audio-player');
    audio_player.play();
    $(this).addClass('playing').text('PLAYING');
    $('#audio-player').show();
});

$(document).on('click', '.pause-audio-btn', function (e) {
    e.preventDefault();
    let audio_player = document.getElementById('audio-player');
    audio_player.pause();
    $('.play-audio-btn').removeClass('playing').text('PLAY');
    $('#audio-player').hide();
});


// HOOK NOTES
// Hooks need to start with 'use'
// By adding a dependency for useEffect, it will only update when something in that dep changes
// By adding [] to the dep, it will only run once
// For GA, the location.pathname changes every time because the 'key' changes in the object
// By limiting it to location.pathname it's more accurate

function useFoundation() {
    useEffect(() => {
        console.log('Foundation Initialized');
        $(document).foundation();
    })
}

ReactGA.initialize('UA-4512414-2');

function useGoogleAnalytics() {
    const location = useLocation();
    useEffect(() => {
        console.log('Google Analytics Update');
        ReactGA.pageview(location.pathname);
    }, [location.pathname])
}

function usePageChange() {
    useEffect(() => {
        if (Foundation.MediaQuery.only('small') === true) {
            console.log('Mobile Nav Hidden');
            $('.top-bar').hide();
        }
    })
}


function App() {

    useFoundation();
    usePageChange();
    useGoogleAnalytics();

    return (
        <React.Fragment>

            <Switch>

                <Route exact path='/'>
                        <Route exact path="/" component={Splash}/>
                </Route>

                <Route>
                    <Header/>
                    <Navigation/>
                    <Main>
                        <Switch>
                            <Route exact path="/intro/" component={Intro}/>
                            <Route exact path="/approach/" component={Approach}/>
                            <Route exact path="/coaching/" component={Coaching}/>
                            <Route exact path="/kudos/" component={Kudos}/>
                            <Route exact path="/about-us/" component={About}/>
                            <Route exact path="/resources/" component={Resources}/>
                            <Route exact path="/tricks-of-the-trade/" component={Tricks}/>
                            <Route path='*' component={NoMatch}/>
                        </Switch>
                    </Main>
                    <Footer/>
                </Route>

            </Switch>
        </React.Fragment>
    )
}


export default App;
