import React from 'react'

export const Main = (props) => (
    <main className={'main'}>
        <div className={'grid-container'}>
            <div className={'grid-x grid-padding-x grid-padding-y'}>

                <div className="cell book-banner hide-for-medium text-center">
                    <div className="callout">
                        <div className="grid-x align-middle">
                            <div className={'small-5'}>
                                <a href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships-ebook/dp/B084WZZMNM/'}
                                   target={'_blank'} rel={'noopener noreferrer'}>
                                    <img
                                        src="/images/site/global/billie-shepard-amazon-presentation-method-mobile.png"
                                        alt="Billie Shepard"/>
                                </a>

                            </div>
                            <div className="small-7 text-left">
                                <h6>The Billie Shepard <br/>PRESENTATION METHOD</h6>
                                <p>Why an acting teacher holds the key to greater influence, happier customers, and
                                    better
                                    relationships</p>
                                <p>
                                    <a className={'amazon-btn'}
                                       href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships-ebook/dp/B084WZZMNM/'}
                                       target={'_blank'} rel={'noopener noreferrer'}>
                                        <img src="/images/site/global/billie-shepard-amazon-button.png"
                                             alt="Amazon"/>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={'cell medium-4 large-4 show-for-medium sidebar'}>

                    <div className={'sidebar-background'}>
                        <img src="/images/site/global/billie-shepard-amazon-presentation-method.png" alt=""/>

                        <div className={'sidebar-content'}>
                            <h4>The Billie Shepard <br/>
                                PRESENTATION <br/>
                                METHOD</h4>
                            <p>Why an acting teacher holds the key to greater influence, happier customers, and
                                better
                                relationships</p>

                            <p>
                                <a className={'amazon-btn'}
                                   href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships-ebook/dp/B084WZZMNM/'}
                                   target={'_blank'} rel={'noopener noreferrer'}>
                                    <img src="/images/site/global/billie-shepard-amazon-button.png" alt="Amazon"/>
                                </a>
                            </p>

                        </div>
                    </div>

                    <div className={'sidebar-background'}>

                        <div className={'sidebar-content'}>
                            <h4>VIRTUAL TRAINING <br/>
                                Groups and <br/>
                                1:1 Coaching
                            </h4>


                            <p>For details, call <br/>
                                <a href={'tel:16502796144'}><strong>650-279-6144</strong></a></p>

                            <p>or email <br/><a
                                href={'mailto:billie@billieshepard.com'}><strong>billie@billieshepard.com</strong></a>
                            </p>

                        </div>

                    </div>

                </div>
                <div className={'cell medium-8 large-8 content'}>
                    {props.children}
                </div>

                <div className="cell contact-banner hide-for-medium text-center">
                    <div className="callout">
                        <h6>Private Virtual or 1:1 Coaching</h6>
                        <p>For details, call <a href={'tel:16502796144'}><strong>650-279-6144</strong></a> or
                            email <br/><a
                                href={'mailto:billie@billieshepard.com'}><strong>billie@billieshepard.com</strong></a>
                        </p>
                    </div>
                </div>

            </div>
        </div>
    </main>
)

