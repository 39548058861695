import React from "react";
import {Helmet} from "react-helmet";

export const NoMatch = () => (
    <div className={'grid-container'}>
        <Helmet>
            <title>404 - Page Not Found</title>
            <meta name="description" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <meta name="keywords" content="interview, effective, interviewing, better, confident, confidence, taped, playback" />
            <body className={'site-error'}/>
        </Helmet>
        <div className={'grid-x grid-padding-x grid-padding-y'}>
            <div className={'cell text-center'}>
                <div className="callout">
                    <h1>404 - Page not found</h1>
                </div>
            </div>
        </div>
    </div>
)