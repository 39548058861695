import React from "react";
import {Helmet} from "react-helmet";

export const Tricks = () => (

    <div className={'grid-x grid-padding-x grid-padding-y'}>

        <Helmet>
            <title>Tricks of the Trade</title>
            <meta name="description"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <meta name="keywords"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <body className={'site-tricks'}/>
        </Helmet>

        <div className={'cell'}>

            <h1>Tricks of the Trade</h1>
            <p>The first thing to know about public speaking is this: Everyone is scared! We offer some quick tips and
                tricks to help you manage the beast.</p>

            <h4>TRICKS AND TIPS</h4>

            <p><strong>Prepare</strong> An actor must prepare for each performance. We call it getting grounded. The
                actor must control the room or the room will control the actor. The same is true for public speaking.
                Before making a presentation,
                turn off the phone and put a note on the door that you are not to be disturbed. Sit quietly and keep
                your breath
                moving for three minutes at whatever pace is comfortable. When you focus on breathing, you are present.
                The
                more you concentrate on the inflow and outflow of your breath, the less inclined you will be to worry
                about the
                presentation you are about to make.</p>

            <p><strong>Project</strong> You have to be heard, so learn to project your voice. A good exercise is to lie
                on
                your back with a
                book
                on your stomach. Imagine someone on the other end of the room holding a baseball glove. As you speak the
                letters
                of the alphabet, imagine that you are throwing them into the glove. Or imagine that your child is across
                the
                street and is about to step in front of a moving car. If your child is in danger, you'll project.</p>

            <p><strong>Enunciate</strong> In addition to being audible, your words must be understandable. You can
                improve
                your diction by
                clenching a pencil just inside your teeth - we're not kidding - and reading out loud. This exercise
                strengthens
                the lips and tongue and helps refine their movement over the teeth.</p>

            <p><strong>15-Second Rule</strong> You should be able to state the three major points of your presentation
                in
                less than 15
                seconds.
                If you can't, you are likely to ramble.</p>


            <p><strong>LISTEN TO BILLIE’S “GET READY” AUDIO MEDITATION</strong></p>

            <p><a href={'#'} className={'button small play-audio-btn'}>PLAY</a>
                <a href={'#'} className={'button small pause-audio-btn'}>PAUSE</a></p>

            <p>
                <audio controls id={'audio-player'}>
                    <source src={'/media/audio/get_ready.mp3'} type={'audio/mpeg'}/>
                </audio>
            </p>


            <h4>IMPROMPTU SPEAKING</h4>

            <h5>THINK</h5>
            <p>Break topic into a pattern of organization:</p>

            <p>Past, present and future <br/>
                or <br/>
                Topic 1,2,3 <br/>
                or <br/>
                Pros and cons</p>

            <h5>THEN SPEAK</h5>
            <p>Make introductory remarks, thank yous, I’m happy to speak on…</p>

            <p>Develop a clear preview of your main points <br/>
                then <br/>
                Deliver the body of the presentation <br/>
                then <br/>
                Review the main points <br/>
                then <br/>
                Conclude the presentation with a strong, positive statement</p>


            <h4>ORGANIZING YOUR SPEECH</h4>

            <h5>What a Successful Opening Does</h5>

            <ul>
                <li> Gets the audience’s attention</li>
                <li> Introduces the topic</li>
                <li>Helps the audience relate to the speaker’s message</li>
                <li>Takes five to ten percent of the total speech time</li>
            </ul>

            <h5>Opening Techniques</h5>

            <ul>
                <li>Explain why the topic is important</li>
                <li>Make a surprising statement</li>
                <li>Create suspense or curiosity</li>
                <li>Tell a story or anecdote</li>
                <li>Ask a rhetorical question</li>
                <li>Begin with a quotation</li>
                <li>Refer to the occasion</li>
            </ul>


            <h5>What a Successful Conclusion Does</h5>

            <ul>
                <li>Gives a feeling of closing</li>
                <li>Review the main points</li>
                <li>Makes an impact</li>
                <li>Takes five to ten percent of the total speech time</li>
            </ul>

            <h5>Closing Techniques</h5>
            <ul>
                <li>End with a quotation</li>
                <li>Tell a story or anecdote</li>
                <li>Call for action</li>
                <li>Ask rhetorical question</li>
            </ul>


            <h3>VIRTUAL PRESENTING TIPS</h3>

            <ul>
                <li><strong>Pre-plan</strong> and <strong>organize</strong> MORE for your virtual meetings and presentations.</li>
                <li><strong>Be concise!</strong> “The ability to <strong>simplify</strong> means to eliminate the unnecessary so that the necessary may speak.” - Hans Hofmann</li>
                <li><strong>Connect with the camera</strong> when responding to questions, and during your critical points. <br/>
                    <strong>*Actor tip</strong>: Personalization: Practice putting someone you know in the camera lens.</li>
                <li><strong>Invest in good lighting</strong> and sound.</li>
                <li><strong>Dress for success</strong>. Men: A nice, solid colored shirt or polo shirt. Ladies: A nice solid color blouse, shirt or sweater.</li>
                <li><strong>Background clean</strong>, a plain wall or a simple background <br/>
                    (Options: Zoom background or Blur)</li>
                <li><strong>Place your camera lens</strong> at your eye-level.</li>
                <li><strong>How far should you sit</strong> from your computer screen? Arms-length from your computer is a good general rule.</li>
                <li><strong>Keep the video on</strong> in smaller meetings. <br/>
                    Quality of communication 62% higher <br/>
                    Engagement 73% higher <br/>
                    Understanding 50% higher.</li>
                <li><strong>Fight Zoom Apnea</strong>. BREATHE deeply and more often. Mind your posture.</li>
                <li><strong>Take down your slides</strong> when not referring directly to the information. Faces are more interesting than slides.</li>
                <li><strong>Engage your attendees early and often</strong>. Don’t go more than 5 minutes without engaging your audience. Activate your meetings with questions, actions and activities: ask for feedback.</li>
            </ul>

            <h3>GOT QUESTIONS?</h3>

            <p>Email Billie: <a href={'mailto:billie@billieshepard.com'}>billie@billieshepard.com</a>.</p>

        </div>
    </div>
)