import React from "react";
import {Helmet} from 'react-helmet';

export const Intro = () => (
    <div className={'grid-x grid-padding-x grid-padding-y'}>
        <Helmet>
            <title>Intro</title>
            <meta name="description"
                  content="Public Speaking training for more effective confident interviewing with one-on-one coaching and taped playback."/>
            <meta name="keywords"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <body className={'site-intro'}/>
        </Helmet>
        <div className={'cell'}>
            <h1>Are you taking advantage of the benefits of virtual meetings? </h1>

            <p><strong>NEW! </strong>We’re offering condensed workshops that seamlessly incorporate simple yet powerful virtual tips into Billie’s proven presentation training methods.</p>

            <h5>Learn how to: </h5>
            <ul>
                <li>Use the power of the camera</li>
                <li>Sharpen vocal clarity</li>
                <li>Identify your audience and manage their attention</li>
                <li>Clarify your intention to overcome nerves</li>
                <li>Eliminate the unnecessary to be concise</li>
                <li>Integrate flow and transitions throughout your narrative</li>
                <li>Decide when to use slides...or not!</li>
                <li>Prepare for audience questions</li>
            </ul>

            <p><strong>We customize to meet your needs. </strong></p>

            <h5>Learn more: </h5>
            <p>Call: <a href="tel:16502796144">650-279-6144</a><br/>
                <a href="mailto:billie@billieshepard.com">billie@billieshepard.com</a></p>


            <h5>LISTEN TO BILLIE’S “GET READY” AUDIO MEDITATION</h5>

            <p><a className={'button small play-audio-btn'}>PLAY</a> <a
                className={'button small pause-audio-btn'}>PAUSE</a></p>

            <audio controls id={'audio-player'}>
                <source src={'/media/audio/get_ready.mp3'} type={'audio/mpeg'}/>
            </audio>
        </div>
    </div>
)

