import React from "react";
import {Helmet} from "react-helmet";

export const Resources = () => (
    <div className={'grid-x grid-padding-x grid-padding-y'}>
        <Helmet>
            <title>Resources</title>
            <meta name="description"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <meta name="keywords"
                  content="interview, effective, interviewing, better, confident, confidence, taped, playback"/>
            <body className={'site-resources'}/>
        </Helmet>

        <div className={'cell'}>
            <h1>Resources</h1>
            <h4>ARTICLES</h4>

            <ul className={'resources'}>
                <li>
                    <a data-open={'resources-1'}>
                        <em>“One of the Biggest Obstacles to Winning Presentations is Fear”</em> <br/>
                    Silicon Valley/San Jose Business Journal</a>
                </li>
            </ul>

            <div className="reveal large" id="resources-1" data-reveal="">

                <div className="grid-x grid-padding-x grid-padding-y">
                    <div className="cell">
                        <h2>"One of the Biggest Obstacles to Winning Presentations is Fear"</h2>
                        <p>Silicon Valley/San Jose Business Journal, August 31, 2001</p>

                        <p>If you bore me, you lose the right to lead me.</p>

                        <p>When making a presentation you have the huge responsibility of keeping your audience's
                            attention
                            and
                            getting the message across.</p>

                        <p>This is one of the lessons I teach actors and executives. As an actor, I have appeared on
                            national
                            TV ads and shows such as "Nash Bridges," and numerous plays at the San Jose Repertory
                            Theatre
                            and
                            other venues. As an acting coach, I have come to realize that the same principles used by
                            actors
                            also apply to the critical business skill of making presentations.</p>

                        <p>One of the biggest obstacles to making winning presentations is fear. You may think that you
                            are
                            the
                            only one afraid of public speaking, but everyone is afraid of performing in front of an
                            audience,
                            including actors. Fear and anxiety are really unfocused energy. Actors learn to focus and
                            rechannel
                            this energy, and so can you.</p>

                        <p>Here are a few tricks of the trade:</p>

                        <p><strong>Prepare</strong> -- an actor must prepare for each performance. We call it getting
                            grounded. The actor
                            must
                            control the room or the room will control the actor. The same is true for public speaking.
                            Before
                            making a presentation, turn off the phone and put a note on the door that you are not to be
                            disturbed. Sit quietly and keep your breath moving for three minutes at whatever pace is
                            comfortable. When you focus on breathing, you are present. The more you concentrate on the
                            inflow
                            and outflow of your breath, the less inclined you wil be to worry about the mistake you made
                            a
                            few
                            minutes ago or about the presentation you are about to make.</p>

                        <p><strong>Project</strong> -- you have to be heard, so learn to project your voice. A good
                            exercise
                            is to lie on
                            your
                            back with a book on your stomach. Imagine someone on the other end of the room holding a
                            baseball
                            glove. As you speak the letters of the alphabet, imagine that you are throwing them into the
                            glove.
                            Or imagine that your child is across the street and about to step in front of a moving car.
                            If
                            your
                            child is in danger, you'll project.</p>

                        <p><strong>Diction</strong> -- in addition to being audible, your words must be understandable.
                            You
                            can improve your
                            diction by putting a pencil accross your mouth and reading out loud. This exercise
                            strengthens
                            the
                            lips, tongue and teeth.</p>

                        <p><strong>15-Second Rule</strong> -- you should be able to state the three major points of your
                            presentation in less
                            than 15 seconds. If you can't, you are likely to prattle on.</p>

                        <p>And why three major points?</p>

                        <p>It's because everything in entertainment, from humor to drama, happens in threes.</p>

                        <p>It is more important than ever to have that extra edge. For a long time the valley was a
                            technological no-touching sort of place. Now people value human contact. That's when
                            passion,
                            communication and creativity come into play. That's when things really start to happen.</p>

                        <p className={'text-center'}><a className={'button'} data-close="">Close</a></p>
                    </div>
                </div>
                <button className="close-button" data-close="" aria-label="Close modal" type="button">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <p>

            </p>


            <h4>BOOKS</h4>

            <ul className={'resources'}>
                <li>
                    <a href={'https://www.amazon.com/Billie-Shepard-Presentation-Method-Relationships/dp/1734532904/ref=sr_1_1?dchild=1&keywords=billie+shepard&qid=1592853209&sr=8-1'}
                       target={'_blank'} rel={'noopener noreferrer'}>
                        <em>The Billie Shepard Presentation Method</em> by Billie Shepard
                    </a>
                </li>
                <li>
                    <a href={'https://scholarworks.sjsu.edu/etd_theses/4313/'}
                       target={'_blank'} rel={'noopener noreferrer'}>
                        <em>The Actor Within: An Exploration of Present Moment Awareness in Business Presentations</em> by Billie Shepard
                    </a>
                </li>
            </ul>

            <h4>SUGGESTED READING</h4>

            <ul className={'resources'}>
                <li>An Improvised Life: A Memoir by Alan Arkin</li>
                <li>Audition Psych 101 by Michael Kostroff</li>
                <li>Presentation Zen by Garr Reynolds Winning Presentations by Jerry Weissman</li>
                <li> I Can See You Naked by Ron Hoff</li>
                <li>Q&A A Survival Guide for Tough, Trick and Hostile Questions by Myles Martel, Ph.D.</li>
            </ul>

            <h4>TRY AN IMPROVISATION OR ACTING CLASS</h4>

            <p>You’ll have fun and internalize proven public speaking and communication techniques that will serve you
                the
                rest of your life.</p>

        </div>
    </div>
)